const webUrlBase = process.env.REACT_APP_API_URL_BASE;

export const urlContact = `${webUrlBase}/api/web/v2/companies/register_new_lead`;
export const urlLogin = `${webUrlBase}/api/web/v2/sessions`;
export const urlPackages = `${webUrlBase}/api/web/v2/packages`;
export const urlSignupUser = `${webUrlBase}/api/web/v2/passengers`;
export const urlSignupCompany = `${webUrlBase}/api/web/v2/companies/signup`;
export const urlRecoverPassword = `${webUrlBase}/api/web/v2/passengers/request_password_reset`;
export const urlUpdatePassword = `${webUrlBase}/api/web/v2/passengers/reset_password`;
export const urlUpdateTermsAndConditions = (passengerId) =>
  `${webUrlBase}/api/web/v2/passengers/${passengerId}/update_acceptance_of_terms_and_conditions`;
export const urlRate = `${webUrlBase}/api/web/v2/packages`;

export const urlIntegrationLogin = `${webUrlBase}/third/sessions`;

export const urlGetOrders = `${webUrlBase}/api/web/integrations/orders`;
export const urlExportOrders = `${webUrlBase}/api/web/integrations/orders/export`;

export const urlActivationShopify = `${webUrlBase}/api/web/shopify/auth`;
export const urlGetShopifyStores = `${webUrlBase}/api/web/shopify/stores`;
export const urlGetShopifyOrders = `${webUrlBase}/api/web/shopify/order`;
export const urlDeleteStoreShopify = `${webUrlBase}/api/web/shopify/auth`;
export const urlAuthorizeShopify = `${webUrlBase}/api/web/shopify/auth/new`;

export const urlActivationWoocomerce = `${webUrlBase}/api/web/woocommerce/auth`;
export const urlGetWoocommerceStores = `${webUrlBase}/api/web/woocommerce/stores`;
export const urlDeleteWoocomerceStore = `${webUrlBase}/api/web/woocommerce/auth`;
export const urlAuthorizeWoocomerce = `${webUrlBase}/api/web/woocommerce/auth/new`;

export const urlActivationMercadoflex = `${webUrlBase}/third/mercadolibre/mercadoflex/update_user_code`;
export const urlGetMercadoflexStores = `${webUrlBase}/api/web/mercadoflex/stores`;
export const urlDisableIntegration = `${webUrlBase}/api/web/v2/user_provider_integrations`;

export const urlActivationVtex = `${webUrlBase}/api/web/vtex/auth`;

export const urlPqrs = `${webUrlBase}/api/web/v2/companies/pqr`;

export const urlCheckin = `${webUrlBase}/api/web/v2/sessions/checkin`;
export const urlSuggestionAddress = `${webUrlBase}/api/web/v2/location/suggestions`;
export const urlGetAddress = `${webUrlBase}/api/web/v2/location/r`;
export const urlQuote = `${webUrlBase}/api/web/v2/bookings/quote`;
export const urlCreateBooking = `${webUrlBase}/api/web/v2/bookings`;
export const urlBulkLoad = `${webUrlBase}/api/web/v2/bookings/bulk_load`;
export const urlOptimizeStops = `${webUrlBase}/api/web/v2/bookings/optimize_stops`;
export const urlBookings = `${webUrlBase}/api/web/v2/bookings`;
export const urlShopifyBooking = `${webUrlBase}/api/web/shopify/booking`;
export const urlExcelBookings = `${webUrlBase}/web/v2/bookings`;
export const urlBookingsPackages = `${webUrlBase}/api/web/v2/packages`;
export const urlExcelPackages = `${webUrlBase}/web/v2/packages`;
export const urlSales = `${webUrlBase}/api/web/v2/delivery_sales`;
export const urlIntegrations = `${webUrlBase}/api/web/v2/delivery_sales_integration`;
export const urlSynchronizeSales = `${webUrlBase}/api/web/v2/user_provider_integrations/synchronize`;
export const urlExcelSales = `${webUrlBase}/web/v2/delivery_sales`;

//Payments
export const urlPicash = `${webUrlBase}/api/web/v2/wallet_account`;
export const urlPicashExcel = `${webUrlBase}/web/v2/wallet_account`;
export const urlBankAccounts = `${webUrlBase}/api/web/v2/bank_accounts`;
export const urlBankList = `${webUrlBase}/api/web/v2/cash_out_bank_account_settings/bank_list`;
export const urlAccountTypeList = `${webUrlBase}/api/web/v2/cash_out_bank_account_settings/account_type_list`;
export const urlDocumentTypeList = `${webUrlBase}/api/web/v2/cash_out_bank_account_settings/document_type_list`;
export const urlCreateBankAccount = `${webUrlBase}/api/web/v2/bank_accounts`;
export const urlBankAccountsAvailable = `${webUrlBase}/api/web/v2/bank_accounts/available_bank_accounts_for_withdrawal`;
export const urlRechargeMethods = `${webUrlBase}/api/web/v2/recharge_methods`;
export const urlPseParams = `${webUrlBase}/api/web/v2/wallet_account/pse_params`;
export const urlCreditCardsList = `${webUrlBase}/api/web/v2/payment_cards`;
export const urlRecharge = `${webUrlBase}/api/web/v2/wallet_account/recharge`;
export const urlCreditCards = `${webUrlBase}/api/web/v2/payment_cards`;
export const urlValidateCreditCards = `${webUrlBase}/api/web/v2/payment_cards/validate`;
export const urlQrs = `${webUrlBase}/api/web/v2/qr_code_users`;

export const urlGroupsCompanies = `${webUrlBase}/api/web/v2/companies`;
export const urlUpdateGroups = `${webUrlBase}/api/web/v2/company_areas`;
export const urlUpdatePasswordUser = `${webUrlBase}/api/web/v2/passengers/update_password`;
export const urlBookingSurvey = `${webUrlBase}/web/v2/bookings`;
export const urlOptimizeRoutes = `${webUrlBase}/api/web/v2/optimizer/batch_optimize`;
export const urlCreateMultipleBookings = `${webUrlBase}/api/web/v2/bookings/create_optimized_bookings`;
export const urlHistoryOptimizedRoutes = `${webUrlBase}/api/web/v2/optimization_stops_files`;
export const urlOptimizedStops = `${webUrlBase}/api/web/v2/optimization_stops_files`;
export const urlUserExists = `${webUrlBase}/api/web/v2/passengers/exists_passenger`;
export const urlCreateUserCompany = `${webUrlBase}/api/web/v2/passengers/create_passenger_with_area_in_company`;
export const urlEditUserCompany = `${webUrlBase}/api/web/v2/passengers/edit`;
export const urlRemoveUserCompany = `${webUrlBase}/api/web/v2/passengers/unlink_user_from_the_company`;
export const urlFavoriteAddresses = `${webUrlBase}/api/web/v2/favorite_places`;
export const urlCompanyFavoriteAddresses = `${webUrlBase}/api/web/v2/company_favorite_places`;
export const urlCompanyUseOriginsAsFavorites = `${webUrlBase}/api/web/v2/company_favorite_places/use_favorites_as_origins`;

export const urlRelatedCities = `${webUrlBase}/api/web/v2/sessions/related_cities`;
export const urlExcelOptimizedRoutes = `${webUrlBase}/api/web/v2/optimization_stops_files`;
export const urlBooking = `${webUrlBase}/api/web/bookings`;
export const urlGetShopityTemporal = `${webUrlBase}/api/web/shopify/install`;
export const urlUnqulifiedBookings = `${webUrlBase}/api/web/v2/bookings/unqualified_bookings`;
export const urlRateBooking = `${webUrlBase}/api/web/v2/bookings`;
export const urlBookingReservations = `${webUrlBase}/api/web/booking_reservations`;
export const urlToggleActiveUser = `${webUrlBase}/api/web/v2/passengers/activate_user_in_the_company`;
export const urlExportBookings = `${webUrlBase}/web/v2/bookings/export`;
export const urlExportPackages = `${webUrlBase}/web/v2/packages/export`;
export const urlReportsBooking = `${webUrlBase}/web/v2/bookings/reports`;
export const urlReportPackages = `${webUrlBase}/web/v2/packages/reports`;

export const urlPrepackages = `${webUrlBase}/api/web/v2/pre_packages`;
export const urlGetCompanyPrepackages = `${webUrlBase}/api/web/v2/pre_packages/company_pre_packages`;
export const urlGetUserPrepackages = `${webUrlBase}/api/web/v2/pre_packages/passenger_pre_packages`;
export const urlCancelPrepackage = `${webUrlBase}/api/web/v2/pre_packages/cancel_pre_package`;
export const urlCompanyRegister = `${webUrlBase}/api/web/v2/companies`;
export const urlCompanyUpdateAcceptanceOfTermsAndConditions = (companyId) =>
  `${webUrlBase}/api/web/v2/companies/${companyId}/update_acceptance_of_terms_and_conditions`;
export const urlCompany = `${webUrlBase}/api/web/v2/companies`;

export const urlCompanyPromoCode = `${webUrlBase}/api/web/v2/companies`;
export const urlPromoCode = `${webUrlBase}/api/web/v2/corporative_promo_codes/redeem`;

export const urlBills = `${webUrlBase}/api/web/v2/electronic_bills`;
export const urlPaymentData = `${webUrlBase}/api/web/v2/electronic_bills/payment_data`;

export const urlBulkScheduled = `${webUrlBase}/api/web/v2/bookings/bulk_scheduled_upload`;
export const urlValidatePhone = `${webUrlBase}/api/web/v2/passengers`;

export const urlCommodateLoan = `${webUrlBase}/api/web/v2/companies`;

export const urlPQR = `${webUrlBase}/api/web/v2/pqrs`;
export const urlTypePQR= `${webUrlBase}/api/web/v2/pqrs/pqr_types_request`;
export const urlCompanyBalances = `${webUrlBase}/api/web/v2/companies`;
